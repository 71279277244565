import './KfzInsuranceTypesComponent.scss';

import React, { useState } from 'react';

import Container from '../ui/Container/Container';
import Icon from '../ui/Icon/Icon';

const KfzInsuranceTypesComponent = () => {
  const [selected, setSelected] = useState(1);

  const insuranceTypes = [
    {
      name: 'Haftpflicht',
      icon: 'shield-single',
      heading: 'Kfz-Haftpflichtversicherung',
      subHeading: (
        <span>
          Die <strong>gesetzliche Pflichtversicherung</strong> übernimmt die Schäden, die Sie an{' '}
          <strong>fremden Fahrzeugen</strong> und Personen verursachen.
        </span>
      ),
      basics: [
        <span key="1">
          Unfallschäden an <strong>anderen Fahrzeugen</strong>
        </span>,
        <span key="2">
          <strong>Personen-</strong>, <strong>Sach-</strong> und <strong>Vermögens</strong>
          schäden, die bei anderen entstehen
        </span>
      ],
      suitable: [
        <span key="1">
          Für <strong>ältere Gebrauchtwagen</strong> mit{' '}
          <strong>niedrigem Wiederverkaufswert</strong>
        </span>,
        <span key="2">
          Wenn Sie Schäden am eigenen Auto <strong>aus der eigenen Tasche bezahlen können</strong>
        </span>
      ],
      addons: ''
    },
    {
      name: 'Teilkasko',
      icon: 'shield-double',
      heading: 'Kfz-Teilkaskoversicherung',
      subHeading: (
        <span>
          Die Teilkaskoversicherung kommt für Schäden an Ihrem{' '}
          <strong>eigenen Auto auf, die Sie nicht selbst verursacht haben.</strong>
        </span>
      ),
      basics: [
        <span key="1">
          Alle Leistungen der <strong>Haftpflichtversicherung</strong>
        </span>
      ],
      suitable: [
        <span key="1">
          Für <strong>Gebrauchtwagen</strong> mit <strong>hohem Wiederverkaufswert</strong>
        </span>,
        <span key="2">
          Wenn Sie Schäden am eigenen Auto{' '}
          <strong>nicht aus der eigenen Tasche bezahlen können</strong>
        </span>
      ],
      addons: (
        <span>
          Zusätzlich Absicherung von <strong>Schäden</strong> am <strong>eigenen Fahrzeug</strong>,
          bspw. <strong>Diebstahl</strong>, <strong>Tierbiss</strong>, <strong>Unwetter</strong> (u.
          a. durch Klimawandel), <strong>Brand</strong>, <strong>Glasbruch</strong> oder{' '}
          <strong>Zusammenstoß</strong> mit einem Tier.
        </span>
      )
    },
    {
      name: 'Vollkasko',
      icon: 'shield-triple',
      heading: 'Kfz-Vollkaskoversicherung',
      subHeading: (
        <span>
          Die <strong>Vollkaskoversicherung</strong> zahlt auch für Schäden an Ihrem eignen
          Fahrzeug, die Sie <strong>selbst verursacht</strong> (aber nicht vorsätzlich
          herbeigeführt) haben.
        </span>
      ),
      basics: [
        <span key="1">
          Alle Leistungen der <strong>Haftpflicht</strong>- und <strong>Teilkasko</strong>
          versicherung
        </span>
      ],
      suitable: [
        <span key="1">Für Neuwagen mit hohem Restwert</span>,
        <span key="2">
          Wenn Sie Schäden am eigenen Auto{' '}
          <strong>nicht aus der eigenen Tasche bezahlen können</strong>
        </span>,
        <span key="3">
          Für <strong>geleaste</strong> Fahrzeuge
        </span>
      ],
      addons: (
        <span>
          Zusätzlich Absicherung von selbstverursachten <strong>Unfallschäden</strong> am{' '}
          <strong>eigenen</strong> Fahrzeug und Schäden durch <strong>Vandalismus</strong>.
        </span>
      )
    }
  ];

  return (
    <Container>
      <div className="KfzInsuranceTypesComponent">
        <h2>
          Welcher <strong>Versicherungsumfang</strong> passt zu meinem <strong>Auto</strong>?
        </h2>
        <div className="KfzInsuranceTypesComponent__icons">
          {insuranceTypes.map((insurance, i) => {
            return (
              <div
                key={i}
                onClick={() => setSelected(i)}
                className={`KfzInsuranceTypesComponent__iconContainer${i == selected ? ' KfzInsuranceTypesComponent__iconContainer--selected' : ''}`}
              >
                <Icon type={insurance.icon} />
                <div>{insurance.name}</div>
              </div>
            );
          })}
        </div>
        <div className="KfzInsuranceTypesComponent__sectionWrapper">
          <h3>{insuranceTypes[selected].heading}</h3>
          <p> {insuranceTypes[selected].subHeading}</p>
          <div className="KfzInsuranceTypesComponent__boxWrapper">
            <div className="KfzInsuranceTypesComponent__boxWrapperInner">
              <div className="KfzInsuranceTypesComponent__leftBox">
                <h4>
                  Was <strong>ist versichert?</strong>
                </h4>
                <ul>
                  {insuranceTypes[selected].basics.map((basic, i) => (
                    <li key={i}>{basic}</li>
                  ))}
                </ul>
              </div>
              {insuranceTypes[selected].addons && (
                <div className="KfzInsuranceTypesComponent__advantageWrapper">
                  {insuranceTypes[selected].addons}
                </div>
              )}
            </div>
            <div className="KfzInsuranceTypesComponent__boxWrapperInner">
              <div className="KfzInsuranceTypesComponent__rightBox">
                <h4>
                  <strong>Für wen bzw. welche Fahrzeuge</strong> ist sie geeignet?
                </h4>
                <ul>
                  {insuranceTypes[selected].suitable.map((suits, i) => (
                    <li key={i}>{suits}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default KfzInsuranceTypesComponent;
